/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

export interface OrgaoAdmin {
  id: number;
  nome: string;
  sigla: string;
  idTipoOrgao: number;
  circunscricao: string;
  codigoMunicipio: string;
  dominioEmail: string;
}

@Injectable({
  providedIn: "root",
})
export class OrgaosAdminService {
  private readonly http = inject(HttpClient);
  adminUrl = `${environment.apiUrl}admin`;
  orgaosUrl = `${this.adminUrl}/orgao`;

  getOrgaosByTermo(termo: string): Observable<any> {
    return this.http.get(`${this.adminUrl}/orgao/termo/${termo}`);
  }
}
